import { observer } from "mobx-react-lite";
import { requireUserById } from "../../store/users/actions";
import { join } from "lodash";

function UserInfo({ uid }) {

    if (!uid) {
        return ""
    }

    const _usr = requireUserById(uid)

    if (_usr.fname || _usr.lname) {
        const _tmp = []
        if (_usr.fname) {
            _tmp.push(_usr.fname)
        }
        if (_usr.lname) {
            _tmp.push(_usr.lname)
        }

        return join(_tmp, ' ')
    }

    if (_usr.email) {
        return _usr.email
    }

    return uid
}

export default observer(UserInfo)