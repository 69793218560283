import { observer } from "mobx-react-lite";
import root from "../../store";
import { Button, Space } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { hideAllErrors } from "../../store/error-info/actions";
import { useTranslation } from "react-i18next";
import { values } from "mobx";

function ErrorInfo() {
    const [t] = useTranslation("common");

    if (!root.errorInfo.hasErr) {
        return null;
    }

    return (
        <div className="feedbackBox mb-1">
            <Button
                type="link"
                className="btn-style-1 btn-large"
                style={{
                    width: "100%",
                    backgroundColor: "#f2f2f2",
                    padding: 5,
                }}
                onClick={hideAllErrors}
            >
                <Space className="mb-2">
                    <div style={{ color: "#53539b" }}>
                        {t("general.removeErrs")}
                    </div>
                    <DeleteOutlined
                        color="#53539b"
                        style={{ color: "#53539b" }}
                    />
                </Space>

                {values(root.errorInfo.errs).map((err) => {
                    return (
                        <div
                            key={err.id}
                            style={{
                                padding: "0 3px",
                                whiteSpace: "normal",
                                textAlign: "left",
                                color: "red",
                                borderBottom: "1px dashed red",
                            }}
                        >
                            {t(`errors.${err.ti?.lbl}`)}
                        </div>
                    );
                })}
            </Button>
        </div>
    );
}

export default observer(ErrorInfo);
