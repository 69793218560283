import * as Sentry from "@sentry/react";

import { appEnvironment } from "../config/const";
import { lgError, lgInfo } from "../modules/logging";

const _handler = {
    notifyExtra: (err, extra) => {
        lgError(err);

        if (appEnvironment === "production") {
            Sentry.captureException(err, { extra });
        }
    },

    notify: (err) => {
        lgError(err);

        if (appEnvironment === "production") {
            Sentry.captureException(err);
        }
    },

    notifyMsg: (msg) => {
        lgInfo(msg);

        if (appEnvironment === "production") {
            Sentry.captureMessage(msg);
        }
    },

    notifyExt: (msg, err) => {
        lgInfo(msg);
        lgError(err);

        if (appEnvironment === "production") {
            Sentry.captureMessage(msg);
            Sentry.captureException(err);
        }
    },

    setContext: (name, context) => {
        if (appEnvironment === "production") {
            Sentry.setContext(name, context);
        }
    },

    setUsrId: (usrId) => {
        if (appEnvironment === "production") {
            Sentry.setUser({
                id: usrId,
                ip_address: "{{auto}}",
            });
        }
    },

    clearUsr: () => {
        if (appEnvironment === "production") {
            Sentry.getCurrentScope().setUser(null);
        }
    },

    setModule: (modKey) => {
        if (appEnvironment === "production") {
            Sentry.setTag("mod", modKey);
        }
    },
};

export const BugCrash = _handler;
