import { observable } from "mobx";
import { DEFAULT_PG_SIZE } from "../../modules/const";

export default observable({
    ids: [],
    pg: 1,
    rpg: DEFAULT_PG_SIZE,
    nr: 0,

    onlyPbs: "n",
});
