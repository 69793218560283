import { initializeApp } from "firebase/app";
import {
    getAuth,
    signOut,
    getIdToken,
    signInWithEmailAndPassword,
} from "firebase/auth";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { updateFromFirebaseUser } from "../store/auth/actions";

const firebaseConfig = {
    apiKey: "AIzaSyBYEskRoVORTmRf1S3ZPxn4pyCsEYs9TpQ",
    authDomain: "nemo-online-393616.firebaseapp.com",
    projectId: "nemo-online-393616",
    storageBucket: "nemo-online-393616.appspot.com",
    messagingSenderId: "52501186094",
    appId: "1:52501186094:web:f99e6096a19661a03181bf",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const storage = getStorage(app);

const auth = getAuth(app);
auth.useDeviceLanguage();

auth.onAuthStateChanged((usr) => {
    //console.log("auth -> onAuthStateChanged", usr);
    updateFromFirebaseUser(usr);
});

export const loginWithEmailAndPwd = async (email, pwd) => {
    try {
        await signInWithEmailAndPassword(auth, email, pwd);
    } catch (err) {
        if (err.code !== "auth/invalid-credential") {
            console.log(err);
        }

        throw err;
    }
};

export const logout = async () => {
    try {
        await signOut(auth);
    } catch (err) {
        console.log(err);
    }
};

export const getAccessToken = async (forceRefresh) => {
    return await getIdToken(auth.currentUser, forceRefresh);
};

export const getAuthCurrentUser = () => {
    return auth.currentUser;
};

export const uploadFileInUsrHome = async (
    file,
    authUid,
    ownerId,
    ownerType,
    fileName,
    fileType,
    refParentId,
    refParentType
) => {
    const _usrHomeRef = ref(storage, `users/${authUid}/${fileName}`);

    await uploadBytes(_usrHomeRef, file, {
        customMetadata: {
            owner: ownerId,
            ownerType,
            fileType,
            src: "nemo-online",
            pID: refParentId,
            pType: refParentType,
        },
    });
};
