import React from "react";
import { BrowserRouter } from "react-router-dom";
import RouteDispatcher from "./components/RouteDispatcher";

import i18next from "i18next";
import "./trans/i18n";
import common_ro from "./trans/ro/common.json";

import "bootstrap/dist/css/bootstrap.min.css";

import locale from "antd/locale/ro_RO";
import { ConfigProvider, Spin } from "antd";

import { Link } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useTranslation } from "react-i18next";
import * as Sentry from "@sentry/react";
import WorkProgressFeedback from "./components/work-progress/WorkProgressFeedback";
import ErrorInfo from "./components/error-info/ErrorInfo";
import Login from "./components/auth/Login";

import root from "./store";
import { observer } from "mobx-react-lite";
import { getAuthCurrentUser, logout } from "./modules/fireb";
import UserInfo from "./components/auth/UserInfo";

Sentry.init({
    dsn: "https://91e8b648dbc7c6e5e103d96fe2c8a994@o4507018457579520.ingest.us.sentry.io/4507018459873280",
    integrations: [],
});

i18next.init({
    interpolation: { escapeValue: false }, // React already does escaping
    lng: "ro", // language to use as default
    resources: {
        ro: {
            common: common_ro,
        },
    },
});

function App() {
    const _authInit = root.auth.IsInit;
    const _loggedIn = !!root.auth.AuthUid;

    const [t] = useTranslation("common");

    if (!_authInit) {
        return (
            <div className="text-center">
                <p className="mt-2">
                    {t("general.init")}, {t("general.pls_wait")}
                </p>
                <Spin />
            </div>
        );
    }

    return (
        <ConfigProvider
            theme={{
                components: {
                    Form: {
                        labelFontSize: 13,
                        verticalLabelPadding: "0 0 3",
                        itemMarginBottom: 20,
                    },
                },
                token: {
                    colorPrimary: "#53539b",
                },
            }}
            locale={locale}
        >
            <BrowserRouter>
                {_authInit && !_loggedIn ? (
                    <Login />
                ) : (
                    <>
                        <Navbar
                            collapseOnSelect
                            expand="md"
                            className="bg-body-tertiary mb-2 mt-0"
                        >
                            <Container fluid>
                                <Navbar.Brand href="#home">NeMo</Navbar.Brand>
                                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                                <Navbar.Collapse id="responsive-navbar-nav">
                                    <Nav className="me-auto">
                                        <Nav.Link as={Link} to="/cmds">
                                            {t("cmds.hd_lst")}
                                        </Nav.Link>

                                        <Nav.Link as={Link} to="/pers">
                                            {t("cmds.hd_pers")}
                                        </Nav.Link>
                                        <Nav.Link as={Link} to="/auto">
                                            {t("cmds.hd_auto")}
                                        </Nav.Link>
                                        <Nav.Link as={Link} to="/unelte">
                                            {t("cmds.hd_unelte")}
                                        </Nav.Link>

                                        <Nav.Link as={Link} to="/sync-reports">
                                            {t("cmds.hd_sync_reports")}
                                        </Nav.Link>
                                    </Nav>
                                    <Nav>
                                        <NavDropdown
                                            title={<UserInfo uid={getAuthCurrentUser()?.uid} />}
                                            id="navbarScrollingDropdown"
                                        >
                                            <NavDropdown.Item href="#action3">
                                                Profile
                                            </NavDropdown.Item>
                                            <NavDropdown.Item href="#action4">
                                                Another action
                                            </NavDropdown.Item>
                                            <NavDropdown.Divider />
                                            <NavDropdown.Item onClick={logout}>
                                                Logout
                                            </NavDropdown.Item>
                                        </NavDropdown>
                                    </Nav>
                                </Navbar.Collapse>
                            </Container>
                        </Navbar>

                        <RouteDispatcher />
                    </>
                )}
                <div className="position-fixed bottom-0 end-0 p-3">
                    <ErrorInfo />
                    <WorkProgressFeedback />
                </div>
            </BrowserRouter>
        </ConfigProvider>
    );
}

export default observer(App);
