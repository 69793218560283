import { observable } from "mobx";

export default observable({
    ids: [5, 10, 20, 30, 40, 50, 60],
    5: {
        id: 5,
        name: "DISPO: neprelucrate",
    },
    10: {
        id: 10,
        name: "DISPO: in pregatire",
    },
    20: {
        id: 20,
        name: "DISPO: in executie",
    },
    30: {
        id: 30,
        name: "DISPO: de asfaltat",
    },
    40: {
        id: 40,
        name: "ABRECHNUNG: in lucru",
    },
    50: {
        id: 50,
        name: "ABRECHNUNG: predate",
    },
    60: {
        id: 60,
        name: "ARHIVA",
    },
});
