import { runInAction } from "mobx";
import root from "../../";
import { Clnt } from "../../../modules/cls/clnt";

export const requireClntById = (id) => {
    let _clnt = root.clnts[id];
    if (!_clnt) {
        _clnt = new Clnt(id);
        root.clnts[id] = _clnt;
    }

    return _clnt;
};

const _clnts = [
    {
        id: 1,
        sname: "DTAG",
        name: "Deutsche Telekom AG",
    },
    {
        id: 2,
        sname: "LW",
        name: "Leonhard Weiss",
    },
    {
        id: 3,
        sname: "PTI21",
        name: "PTI 21",
    },
    {
        id: 4,
        sname: "PTI22",
        name: "PTI 22",
    },
    {
        id: 5,
        sname: "PTI31",
        name: "PTI 31",
    },
    {
        id: 6,
        sname: "PRIVAT-KD.",
        name: "Privat Kunden",
    },
];

export const actLoadClnts = async () => {
    runInAction(() => {
        for (const _clntData of _clnts) {
            const _clnt = requireClntById(_clntData.id);
            _clnt.fromSrv(_clntData);

            root.clnts.ids.push(_clntData.id);
        }
    });
};
