import { action, computed, makeObservable, observable } from "mobx";

export class Usr {
    _id = "";
    _email = "";
    _fname = "";
    _lname = "";
    _adm = 0;
    _rights = 0;

    _fullLoadStarted = null;
    _lastLoad = null;

    constructor(id) {
        this._id = id;

        makeObservable(this, {
            _id: observable,
            _email: observable,
            _fname: observable,
            _lname: observable,
            _adm: observable,
            _rights: observable,

            _fullLoadStarted: observable,
            _lastLoad: observable,

            id: computed,
            email: computed,
            fname: computed,
            lname: computed,
            adm: computed,
            rights: computed,

            fullLoadStarted: computed,
            lastLoad: computed,

            fromSrv: action.bound,
            setEmail: action.bound,
        })
    }

    get id() {
        return this._id;
    }

    get email() {
        return this._email;
    }
    get fname() {
        return this._fname;
    }
    get lname() {
        return this._lname;
    }
    get adm() {
        return this._adm;
    }
    get rights() {
        return this._rights;
    }

    get lastLoad() {
        return this._lastLoad;
    }
    get fullLoadStarted() {
        return this._fullLoadStarted;
    }

    setEmail(val) {
        this._email = val;
    }

    fromSrv(srvJson) {
        //console.log("fromSrv(srvJson)", srvJson);

        this._id = srvJson.id;
        this._email = srvJson.email;
        this._fname = srvJson.fname;
        this._lname = srvJson.lname;
        this._adm = srvJson.adm;
        this._rights = srvJson.rights;

        this._lastLoad = Date.now();
    }
}