import { first } from "lodash";
import { observable, keys, values } from "mobx";
import { computedFn } from "mobx-utils";

/* (iNoRiks-) workProgress */
const _store = observable({
    tasks: {},

    get hasTask() {
        return keys(this.tasks).length > 0;
    },

    containsTask: computedFn(function (taskInfo) {
        return !!first(values(this.tasks), (itm) => itm.ti === taskInfo);
    }),
});

export default _store;
