import { autorun, makeAutoObservable } from 'mobx'

const PERSISTED_FIELDS = ['dateFmt']
const STORAGE_ITM_NAME = 'NemoSession'

class SessionStore {

    dateFmt = 'DD.MM.YYYY'
    dateTimeFmt = 'DD.MM.YYYY HH:mm'

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true })

        const _jsonStorage = window.localStorage.getItem(STORAGE_ITM_NAME)
        if (_jsonStorage) {
            const _objStorage = JSON.parse(_jsonStorage)

            for (const it of PERSISTED_FIELDS) {
                this[it] = _objStorage[it]
            }
        }
    }
}

const _sessionStore = new SessionStore()

autorun(() => {

    const _objStorage = {}
    for (const it of PERSISTED_FIELDS) {
        _objStorage[it] = _sessionStore[it]
    }

    window.localStorage.setItem(STORAGE_ITM_NAME, JSON.stringify(_objStorage))
}, {
    delay: 100
})

export default _sessionStore