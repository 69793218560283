import { observable } from "mobx";

export default observable({
    ids: [1, 2, 3, 4],
    2: {
        id: 2,
        name: "Karlsruhe",
    },
    1: {
        id: 1,
        name: "Stuttgart",
    },
    3: {
        id: 3,
        name: "Heilbronn",
    },
    4: {
        id: 4,
        name: "Ludwigsburg",
    },
});
