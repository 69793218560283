import { makeObservable, observable, computed, values } from "mobx";
import root from "../../store";
import { sortBy } from "lodash";

export class Clnt {
    _id = "";
    _sname = "";
    _name = "";
    _lastLoad = null;

    constructor(id) {
        this._id = id;

        makeObservable(this, {
            _id: observable,
            _sname: observable,
            _name: observable,
            _lastLoad: observable,

            lastLoad: computed,
        });
    }

    get id() {
        return this._id;
    }
    get sname() {
        return this._sname;
    }
    get name() {
        return this._name;
    }

    get lastLoad() {
        return this._lastLoad;
    }

    get sortedAspIds() {
        const _asps = values(root.clntAsps).filter(
            (it) => it.clntId === this.id
        );

        return sortBy(_asps, ["name"]).map((it) => it.id);
    }

    fromSrv(srvJson) {
        //console.log("fromSrv(srvJson)", srvJson);

        this._id = srvJson.id;
        this._sname = srvJson.sname;
        this._name = srvJson.name;

        this._lastLoad = Date.now();
    }
}
