import React, { useState } from "react";
import { loginWithEmailAndPwd } from "../../modules/fireb";
import { Alert, Button, Card, Col, Flex, Form, Input, Row } from "antd";
import { useTranslation } from "react-i18next";

const { Password } = Input;

function Login() {
    const [t] = useTranslation("common");
    const [form] = Form.useForm();
    const [inFlight, setInFlight] = useState(false);
    const [srvErr, setSrvErr] = useState("");

    const initValues = {
        email: "",
        pwd: "",
    };

    const onSave = (vals) => {
        setInFlight(true);

        loginWithEmailAndPwd(vals.email, vals.pwd)
            .catch((err) => {
                if (err.code === "auth/invalid-credential") {
                    setSrvErr(t("login.msg_credent"));
                } else if (err.code) {
                    setSrvErr(t("login.msg_code") + err.code);
                } else {
                    console.error(err);
                    setSrvErr(t("login.msg_unk_err"));
                }
            })
            .finally(() => {
                setInFlight(false);
            });
    };

    return (
        <Row>
            <Col xs={12} offset={6}>
                <Card className="mt-3">
                    <h4 className="mb-3">#NeMo - Netz-Montage CRM</h4>

                    {srvErr && (
                        <Alert
                            message={srvErr}
                            type="error"
                            closable
                            className="mt-2"
                            onClose={() => {
                                setSrvErr("");
                            }}
                        />
                    )}

                    <Form
                        form={form}
                        autoComplete="off"
                        initialValues={initValues}
                        layout="vertical"
                        labelWrap
                        labelAlign="right"
                        onFinish={onSave}
                        className="mt-3"
                    >
                        <Form.Item
                            name="email"
                            label={t("general.email")}
                            rules={[
                                {
                                    required: true,
                                    message: t("login.msg_mail_req"),
                                },
                                {
                                    type: "email",
                                    message: t("login.msg_mail_valid"),
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="pwd"
                            label={t("general.pwd")}
                            rules={[
                                { required: true, message: t("login.msg_pwd") },
                            ]}
                        >
                            <Password />
                        </Form.Item>

                        <Form.Item noStyle wrapperCol={24}>
                            <Flex justify="center">
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={inFlight}
                                    disabled={inFlight}
                                    className="mt-3"
                                >
                                    {t("general.login")}
                                </Button>
                            </Flex>
                        </Form.Item>
                    </Form>
                </Card>
            </Col>
        </Row>
    );
}

export default Login;
