import root from "../../";
import { set } from "lodash";
import { BugCrash } from "../../../modules/bug-crash";
import { runInAction } from "mobx";
import { actClearSession } from "../../session/actions";

export const updateFromFirebaseUser = (usr) => {
    const uid = usr ? usr.uid : null;

    runInAction(() => {
        set(root.auth, "uid", uid);
    });

    if (uid) {
        // after login
        BugCrash.setUsrId(uid);
    } else {
        runInAction(() => {
            actClearSession();
        });
        BugCrash.clearUsr();
    }
};
