import dayjs from "dayjs";
import { observable } from "mobx";
import { DEFAULT_PG_SIZE } from "../../modules/const";

export default observable({
    viewMode: "lst",

    fltSts: "5,10,20,30",
    fltNeedVAO: "",
    fltVAO1: "",
    fltVAO2: "",
    fltVAOx: dayjs().format("YYYY-MM-DD"),
    fltRegion: "",
    fltClnt: "",
    fltTxt: "",
    fltTB: "",
    tbSurfaces: [],
    tbSts: [],
    fltM: "",
    fltFO: "",
    fltTyp: "",
    showVAO: true,
    showComment: true,

    fltCmdsLstId: "",

    materiale: "",

    ids: [],
    pg: 1,
    rpg: DEFAULT_PG_SIZE,

    idsMap: [],
    pgMap: 1,
    rpgMap: 100,

    selIds: [],
    showSelCmds: false,

    nr: 0,
});
