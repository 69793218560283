import { first } from "lodash";
import { observable, keys, values } from "mobx";

/* (iNoRiks-) errorInfo */
const _store = observable({
    errs: {},

    get hasErr() {
        return keys(this.errs).length > 0;
    },

    get firstErr() {
        return first(values(this.errs));
    },
});

export default _store;
