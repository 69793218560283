import { remove, runInAction } from "mobx";
import { getUuid } from "../../../modules/id-generator";
import root from "../../";

export const startTask = (taskInfo) => {
    const id = getUuid();

    runInAction(() => {
        root.workProgress.tasks[id] = {
            id,
            ti: taskInfo,
        };
    });

    return id;
};

export const doneTask = (id) => {
    runInAction(() => {
        remove(root.workProgress.tasks, id);
    });
};
