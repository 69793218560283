import { keyBy } from "lodash";
import axios from "./api";
import { DEFAULT_PG_SIZE } from "./const.js";
import { addGETParams } from "./utils.js";

export const getCmd = async (cmdId) => {
    const { data } = await axios.get(`/cmds/${cmdId}`);

    return data;
};

export const getOneCmdVaoIntvs = async (cmdId) => {
    const { data } = await axios.get(`/cmds/${cmdId}/vao-intervals`);

    return data;
};

export const getOneCmdVaoReqs = async (cmdId) => {
    const { data } = await axios.get(`/cmds/${cmdId}/vao-requests`);

    return data;
};

export const getActCmdsVaoReqs = async () => {
    const { data } = await axios.get("/cmds/act-vao-requests");

    return data;
};

export const getActCmdsVaoIntvs = async () => {
    const { data } = await axios.get("/cmds/act-vao-intervals");

    return data;
};

export const getCmds = async (opt) => {
    const _params = { rpg: opt.rpg || DEFAULT_PG_SIZE, pg: opt.pg || 1 };

    if (opt?.filter?.cmdsLstId) {
        _params["cmdsLstId"] = opt?.filter?.cmdsLstId;
    }
    if (opt?.filter?.sts) {
        _params["sts"] = opt?.filter?.sts;
    }
    if (opt?.filter?.clnt) {
        _params["clnt"] = opt?.filter?.clnt;
    }
    if (opt?.filter?.region) {
        _params["reg"] = opt?.filter?.region;
    }
    if (opt?.filter?.mat) {
        _params["mat"] = opt?.filter?.mat;
    }

    if (opt?.filter?.typ) {
        _params["typ"] = opt?.filter?.typ;
    }
    if (opt?.filter?.tb) {
        _params["tb"] = opt?.filter?.tb;
    }
    if (opt?.filter?.m) {
        _params["mn"] = opt?.filter?.m;
    }
    if (opt?.filter?.txt) {
        _params["srch"] = opt?.filter?.txt;
    }
    if (opt?.filter?.needVAO) {
        _params["needVao"] = opt?.filter?.needVAO;
    }
    if (opt?.filter?.VAO1) {
        _params["vao1"] = opt?.filter?.VAO1;
    }
    if (opt?.filter?.VAO2) {
        _params["vao2"] = opt?.filter?.VAO2;
    }
    if (opt?.filter?.VAOx) {
        _params["vaoX"] = opt?.filter?.VAOx;
    }
    if (opt?.filter?.fo) {
        _params["fo"] = opt?.filter?.fo;
    }
    if (opt?.filter?.tbSurfaces) {
        _params["tbSurf"] = opt?.filter?.tbSurfaces.join(",");
    }
    if (opt?.filter?.tbSts) {
        _params["tbSts"] = opt?.filter?.tbSts.join(",");
    }

    //console.log("getCmds", opt, _params);

    const _url = addGETParams("/cmds", _params);
    //console.log("_url", _url);

    const { data } = await axios.get(_url);

    return data;
};

export const syncCmdById = async (cmdId) => {
    const { data } = await axios.get(`/sync/stgt_akt/${cmdId}`);

    return data;
};

export const getSyncReports = async (opt) => {
    const _params = { rpg: opt.rpg || DEFAULT_PG_SIZE, pg: opt.pg || 1 };

    const _url = addGETParams("/sync/reports", _params);

    const { data } = await axios.get(_url);

    return data;
};

export const getSyncReportById = async (rptId) => {
    const { data } = await axios.get(`/sync/reports/${rptId}`);

    return data;
};

export const getOneCmdTbItms = async (cmdId) => {
    const { data } = await axios.get(`/cmds/${cmdId}/tb-itms`);

    return data;
};

export const getTbItmById = async (tbItmId) => {
    const { data } = await axios.get(`/tb/${tbItmId}`);
    return data;
};

export const saveTbItm = async (tbItmId, data) => {
    return await axios.post(`/tb/${tbItmId}`, data);
};

export const delTbItm = async (tbItmId) => {
    return await axios.delete(`/tb/${tbItmId}`);
};

export const patchCmdTools = async (cmdId, tools) => {
    return await axios.patch(`/cmds/${cmdId}/tools`, { val: tools });
};

export const patchCmdBase = async (cmdId, base) => {
    return await axios.patch(`/cmds/${cmdId}/base`, { val: base });
};

export const createCmd = async (cmdId, data) => {
    return await axios.post(`/cmds/${cmdId}`, data);
};

export const getFltLstItmById = async (fltLstId) => {
    const { data } = await axios.get(`/flt-lst/${fltLstId}`);
    return data;
};

export const saveFltLstItm = async (fltLstId, data) => {
    return await axios.post(`/flt-lst/${fltLstId}`, data);
};

/**
 * @param {*} addCmds - array of cmdIds to add
 * @param {*} delCmds - array of cmdIds to remove
 * @returns
 */
export const addDelFltLstItmCmds = async (fltLstId, addCmds, delCmds) => {
    return await axios.patch(`/flt-lst/${fltLstId}/cmds`, {
        add: keyBy(addCmds || []),
        del: keyBy(delCmds || []),
    });
};

export const getFltLstItms = async () => {
    const { data } = await axios.get(`/flt-lst`);
    return data;
};

export const getUsers = async () => {
    const { data } = await axios.get(`/users`);
    return data;
};

export const getOneCmdComments = async (cmdId) => {
    const { data } = await axios.get(`/cmds/${cmdId}/comments`);

    return data;
};

export const addCmdComment = async (cmdId, comentariu) => {
    await axios.post(`/cmds/${cmdId}/comments`, { comment: comentariu });
};

export const getAdrsPredictions = async (adrs) => {
    const { data } = await axios.get(
        `/adrs/predictions?adrs=` + encodeURIComponent(adrs)
    );
    return data;
};

export const getAdrsPlaceDetails = async (placeId) => {
    const { data } = await axios.get(
        `/adrs/place-details?id=` + encodeURIComponent(placeId)
    );
    return data;
};

export const patchCmdAdrs = async (cmdId, data) => {
    return await axios.patch(`/cmds/${cmdId}/adrs`, data);
};

export const getClntAsps = async (clntId) => {
    const { data } = await axios.get(
        `/clnt-asps/${encodeURIComponent(clntId)}`
    );
    return data;
};

export const saveClntAsp = async (clntAspId, clntId, data) => {
    await axios.post(
        `/clnt-asps/${encodeURIComponent(clntId)}/${encodeURIComponent(
            clntAspId
        )}`,
        data
    );
};
