import React from "react";
import { observer } from "mobx-react-lite";
import Toast from "react-bootstrap/Toast";
import { Space } from "antd";
import { SyncOutlined } from "@ant-design/icons";

import { useTranslation } from "react-i18next";
import rootStore from "../../store";

function WorkProgressFeedback() {
    const [t] = useTranslation("common");

    let msg;

    if (rootStore.workProgress.hasTask) {
        msg = t("general.sync_pls_wait");
    } else {
        return null;
    }

    return (
        <Toast bg="light" show>
            <Toast.Header closeButton={false}>
                <Space size="small">
                    <SyncOutlined spin />
                    <div className="mr-auto">{msg}</div>
                </Space>
            </Toast.Header>
        </Toast>
    );
}

export default observer(WorkProgressFeedback);
