import { observable } from "mobx";

export default observable({
    ids: [1, 2, 3, 4, 5, 6],
    1: {
        id: 1,
        name: "Störung",
    },
    2: {
        id: 2,
        name: "HSAS",
    },
    3: {
        id: 3,
        name: "Rückbau",
    },
    4: {
        id: 4,
        name: "Erweiterung",
    },
    5: {
        id: 5,
        name: "Reklamation",
    },
    6: {
        id: 6,
        name: "Schachtausstieg",
    },
});
