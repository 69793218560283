import { runInAction } from "mobx";
import root from "../../";
import {
    getUsers,
} from "../../../modules/api-service";
import { doneTask, startTask } from "../../work-progress/actions";
import { BugCrash } from "../../../modules/bug-crash";
import { addError } from "../../error-info/actions";
import { Usr } from "../../../modules/cls/usr";

export const requireUserById = (id) => {
    let _usr = root.users[id];
    if (!_usr) {
        _usr = new Usr(id);
        root.users[id] = _usr;
    }

    return _usr;
};

export const actLoadUsers = async () => {
    let _wpId;

    try {
        _wpId = startTask(`actLoadUsers`);

        const _srvResp = await getUsers();

        runInAction(() => {
            for (const _srvItm of _srvResp.itms) {
                const _usr = requireUserById(_srvItm.id)
                _usr.fromSrv(_srvItm);
            }
        });

    } catch (err) {
        addError({
            lbl: "load_users",
        });
        BugCrash.notifyExt("actLoadUsers", err);
    } finally {
        if (_wpId) {
            doneTask(_wpId);
        }
    }
};
